export default [
  {
    title: 'Komads',
    customicon: `<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.949581 8.42375C1.6746 8.18479 2.45484 8.57853 2.6938 9.30355C2.93276 10.0286 2.53902 10.8088 1.814 11.0478C1.08897 11.2867 0.308736 10.893 0.069777 10.168C-0.169182 9.44295 0.224557 8.66271 0.949581 8.42375Z" fill="#6B6B6B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.10225 13.2999C5.47408 13.5072 4.78979 13.1623 4.58251 12.5342L3.1922 8.31708C2.98492 7.6889 3.32978 7.00461 3.95796 6.79733C4.58613 6.59005 5.27042 6.93492 5.4777 7.56309L6.86801 11.7802C7.07529 12.4083 6.73042 13.0926 6.10225 13.2999Z" fill="#808080"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.9947 9.03386C15.9295 9.69281 15.3376 10.178 14.6786 10.1128L4.18344 9.0746C3.52449 9.00943 3.03933 8.41746 3.1045 7.75851C3.16967 7.09956 3.76164 6.6144 4.42059 6.67957L14.9158 7.71778C15.5747 7.78295 16.0599 8.37492 15.9947 9.03386Z" fill="#808080"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6934 0.681123C13.3342 0.517291 13.9932 0.908315 14.1561 1.55007L15.961 8.62832C16.1248 9.26917 15.7338 9.92812 15.092 10.091C14.4512 10.2549 13.7922 9.86385 13.6293 9.2221L11.8244 2.14384C11.6606 1.503 12.0516 0.84405 12.6934 0.681123Z" fill="#808080"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.9285 1.15187C14.3078 1.69406 14.1747 2.44804 13.6325 2.8273L4.99018 8.87188C4.448 9.25114 3.69401 9.11808 3.31475 8.5759C2.93549 8.03371 3.06855 7.27973 3.61073 6.90047L12.2531 0.855888C12.7953 0.476631 13.5493 0.609687 13.9285 1.15187Z" fill="#6B6B6B"/>
</svg>
`,
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Whitelist',
        komicon: 'k-user-tag',
        tagVariant: 'light-warning',
        route: 'komads-whitelist-dashboard',
        resource: 'Komads',
        action: 'manage',
      },
    ],
  },
]
