export default [
  {
    title: 'Kompack',
    customicon: `<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.60892 6.2804C6.34568 6.2804 6.94391 6.8776 6.94391 7.61539C6.94391 8.35318 6.34671 8.95038 5.60892 8.95038C4.87113 8.95038 4.27393 8.35318 4.27393 7.61539C4.27393 6.8776 4.87113 6.2804 5.60892 6.2804Z" fill="#4285F4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1563 12.9543C18.893 12.9543 19.4913 13.5516 19.4913 14.2893C19.4913 15.0271 18.8941 15.6243 18.1563 15.6243C17.4185 15.6243 16.8213 15.0271 16.8213 14.2893C16.8213 13.5516 17.4185 12.9543 18.1563 12.9543Z" fill="#1E5DBA"/>
      <path d="M9.90729 16.9829C9.71335 16.9829 9.51839 16.9357 9.34189 16.8413L5.02292 14.5387C4.63094 14.3293 4.38672 13.922 4.38672 13.4787V10.5727C4.38672 9.90879 4.92441 9.37109 5.58832 9.37109C6.25222 9.37109 6.78991 9.90879 6.78991 10.5727V12.7583L9.90729 14.4207L13.0247 12.7583V8.87445C13.0247 8.21054 13.5624 7.67285 14.2263 7.67285C14.8902 7.67285 15.4279 8.21054 15.4279 8.87445V13.4797C15.4279 13.923 15.1836 14.3314 14.7917 14.5397L10.4727 16.8423C10.2962 16.9367 10.1012 16.9839 9.90729 16.9839V16.9829Z" fill="#4285F4"/>
      <path d="M1.71039 15.4981C1.04648 15.4981 0.508789 14.9604 0.508789 14.2965V5.57747C0.508789 5.13418 0.753008 4.72578 1.14499 4.51748L9.32221 0.157457C9.6752 -0.0313511 10.099 -0.030325 10.453 0.157457L18.6302 4.51748C19.0222 4.72681 19.2664 5.13418 19.2664 5.57747V11.3618C19.2664 12.0257 18.7287 12.5634 18.0648 12.5634C17.4009 12.5634 16.8632 12.0257 16.8632 11.3618V6.29781L9.88761 2.57809L2.91198 6.29781V14.2965C2.91198 14.9604 2.37429 15.4981 1.71039 15.4981Z" fill="#4285F4"/>
      <path d="M18.0648 12.5634C17.4009 12.5634 16.8632 12.0257 16.8632 11.3618V6.29783L9.32219 2.27745C8.73627 1.96551 8.51565 1.23798 8.82759 0.652064C9.13954 0.0671705 9.86706 -0.154471 10.453 0.157472L18.6302 4.51749C19.0222 4.72682 19.2664 5.13419 19.2664 5.57748V11.3618C19.2664 12.0257 18.7287 12.5634 18.0648 12.5634Z" fill="#1E5DBA"/>
      </svg>`,
    action: 'manage',
    children: [
      {
        title: 'Dashboard',
        komicon: 'k-home-hashtag',
        resource: 'Kompack',
        route: 'admin-dashboard-kompack',
        action: 'manage',
      },
      {
        title: 'Mitra Gudang',
        komicon: 'k-profile-2user',
        resource: 'Kompack',
        action: 'manage',
        children: [
          {
            title: 'Data Mitra Gudang',
            icon: 'none',
            resource: 'Kompack',
            route: 'kompack-data-mitra-gudang',
            action: 'manage',
          },
        ],
      },
      {
        title: 'Fulfillment',
        komicon: 'k-box-1',
        resource: 'Kompack',
        action: 'manage',
        children: [
          {
            title: 'Fulfillment Service',
            icon: 'none',
            resource: 'Kompack',
            route: 'fulfillment-service',
            action: 'manage',
          },
          {
            title: 'Riwayat Pengajuan',
            icon: 'none',
            resource: 'Kompack',
            route: 'history-submission',
            action: 'manage',
          },
        ],
      },
    ],
  },
]
