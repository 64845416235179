export default [
  {
    title: 'Management',
    komicon: 'k-cloud-connection',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Staff',
        komicon: 'k-user-octagon',
        route: 'staff',
        resource: 'Management',
        action: 'manage',
      },
      {
        title: 'Divisi',
        komicon: 'k-layer',
        route: 'division',
        resource: 'Management',
        action: 'manage',
      },
      {
        title: 'Posisi',
        komicon: 'k-status-up',
        route: 'position',
        resource: 'Management',
        action: 'manage',
      },
      {
        title: 'Kantor Operasional',
        komicon: 'k-buildings',
        route: 'operational-office',
        resource: 'Management',
        action: 'manage',
      },
      {
        title: 'Working Space',
        komicon: 'k-building-4',
        route: 'work-space',
        resource: 'Management',
        action: 'manage',
      },
    ],
  },
]
