export default [
  {
    title: 'Open Api',
    customicon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 250 260.24">
  <defs>
    <style>
      .cls-1, .cls-2 {
        fill: #cc9212;
      }

      .cls-3, .cls-2 {
        fill-rule: evenodd;
      }

      .cls-3, .cls-4 {
        fill: #fbbc05;
      }
    </style>
  </defs>
  <g>
    <path class="cls-2" d="M176.18,221.96c10.57,0,19.14,8.57,19.14,19.14s-8.57,19.14-19.14,19.14-19.14-8.57-19.14-19.14,8.57-19.14,19.14-19.14Z"/>
    <path class="cls-3" d="M44.17,221.31c10.57,0,19.14,8.57,19.14,19.14s-8.57,19.14-19.14,19.14-19.14-8.57-19.14-19.14,8.57-19.14,19.14-19.14Z"/>
  </g>
  <path class="cls-4" d="M150.67,201.76H44.72c-8.02,0-14.53-6.51-14.53-14.53v-107.05c0-25.31-24.81-46.67-25.06-46.89C-.97,28.12-1.75,18.96,3.42,12.84c5.16-6.12,14.28-6.92,20.42-1.78,1.45,1.21,35.41,30.14,35.41,69.12v92.52h91.43c44,0,60.88-36.8,67.05-58.85h-114.83c-8.02,0-14.53-6.51-14.53-14.53s6.5-14.53,14.53-14.53h132.58c4.11,0,8.04,1.74,10.79,4.8,2.75,3.05,4.08,7.14,3.66,11.23-3.62,34.91-29.02,100.94-99.25,100.94Z"/>
  <path class="cls-1" d="M132.65,146.71c-3.98,0-7.95-1.63-10.82-4.82l-29.64-33c-3.83-4.27-4.79-10.39-2.46-15.63,2.34-5.24,7.53-8.61,13.27-8.61h132.47c8.02,0,14.53,6.51,14.53,14.53s-6.5,14.53-14.53,14.53h-99.89l7.87,8.77c5.36,5.97,4.87,15.16-1.1,20.52-2.77,2.49-6.25,3.72-9.7,3.72Z"/>
  <path class="cls-1" d="M235.47,62.06H103c-8.02,0-14.53-6.51-14.53-14.53s6.5-14.53,14.53-14.53h99.89l-7.87-8.76c-5.36-5.97-4.87-15.16,1.1-20.52,5.97-5.36,15.16-4.87,20.52,1.1l29.64,33c3.83,4.27,4.8,10.39,2.46,15.63-2.34,5.24-7.53,8.61-13.27,8.61Z"/>
<script xmlns=""/><script xmlns=""/><script xmlns=""/><link xmlns=""/></svg>`,
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Dashboard',
        komicon: 'k-home-hashtag',
        tagVariant: 'light-warning',
        route: 'dashboard-open-api',
        resource: 'Open Api',
        action: 'manage',
      },
    ],
  },
]
