export default [
  {
    title: 'Komclass',
    customicon: `<svg xmlns="http://www.w3.org/2000/svg" width="195" height="174" viewBox="0 0 195 174" fill="none">
  <path d="M181.98 173.75C179.69 173.75 177.4 173.12 175.39 171.87L131.64 144.67H12.49C5.59 144.67 0 139.08 0 132.18V12.52C0 5.62003 5.59 0.0300293 12.49 0.0300293H144.47C151.37 0.0300293 156.96 5.62003 156.96 12.52C156.96 19.42 151.37 25.01 144.47 25.01H24.97V119.71H135.2C137.53 119.71 139.81 120.36 141.79 121.59L169.49 138.81V80.66C169.49 73.76 175.08 68.17 181.98 68.17C188.88 68.17 194.47 73.76 194.47 80.66V161.28C194.47 165.81 192.01 169.99 188.05 172.19C186.16 173.24 184.07 173.76 181.99 173.76L181.98 173.75Z" fill="#6D3CB8"/>
  <path d="M181.98 173.75C179.69 173.75 177.4 173.12 175.39 171.87L131.64 144.67H102.39C95.49 144.67 89.9 139.08 89.9 132.18C89.9 125.28 95.49 119.69 102.39 119.69H135.21C137.54 119.69 139.82 120.34 141.8 121.57L169.5 138.79V80.64C169.5 73.74 175.09 68.15 181.99 68.15C188.89 68.15 194.48 73.74 194.48 80.64V161.26C194.48 165.79 192.02 169.97 188.06 172.17C186.17 173.22 184.08 173.74 182 173.74L181.98 173.75Z" fill="#543296"/>
  <path d="M181.64 58.25C188.102 58.25 193.34 53.0118 193.34 46.55C193.34 40.0883 188.102 34.85 181.64 34.85C175.178 34.85 169.94 40.0883 169.94 46.55C169.94 53.0118 175.178 58.25 181.64 58.25Z" fill="#543296"/>
  <path d="M181.64 24C188.102 24 193.34 18.7618 193.34 12.3C193.34 5.8383 188.102 0.600037 181.64 0.600037C175.178 0.600037 169.94 5.8383 169.94 12.3C169.94 18.7618 175.178 24 181.64 24Z" fill="#543296"/>
</svg>`,
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Data Event',
        komicon: 'k-document-1',
        tagVariant: 'light-warning',
        route: 'data-event',
        resource: 'Komclass',
        action: 'manage',
      },
    ],
  },
]
