export default [
  {
    title: 'Komform',
    customicon: `<svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400" fill="none">
    <path d="M140.673 209.636C147.98 209.636 153.904 204.045 153.904 197.147C153.904 190.25 147.98 184.658 140.673 184.658C133.366 184.658 127.442 190.25 127.442 197.147C127.442 204.045 133.366 209.636 140.673 209.636Z" fill="#B96613"/>
    <path d="M321.2 358H78.8237C68.4243 358 60 350.048 60 340.232V59.7677C60 49.9517 68.4243 42 78.8237 42H223.488C233.887 42 242.312 49.9517 242.312 59.7677V110.173C242.312 123.426 253.734 134.208 267.775 134.208H321.176C331.576 134.208 340 142.159 340 151.976V340.21C340 350.026 331.576 357.978 321.176 357.978L321.2 358ZM97.6475 322.465H302.353V169.766H267.775C232.959 169.766 204.641 143.036 204.641 110.173V77.5354H97.6237V322.465H97.6475Z" fill="#B96613"/>
    <path d="M288.693 108.353C299.089 108.353 307.516 100.399 307.516 90.5858C307.516 80.7729 299.089 72.8181 288.693 72.8181C278.297 72.8181 269.869 80.7729 269.869 90.5858C269.869 100.399 278.297 108.353 288.693 108.353Z" fill="#995314"/>
    <path d="M255.733 209.704H178.797C171.467 209.704 165.518 204.088 165.518 197.17C165.518 190.251 171.467 184.636 178.797 184.636H255.733C263.063 184.636 269.012 190.251 269.012 197.17C269.012 204.088 263.063 209.704 255.733 209.704Z" fill="#B96613"/>
    <path d="M255.734 255.46H140.649C133.32 255.46 127.371 249.844 127.371 242.926C127.371 236.007 133.32 230.392 140.649 230.392H255.734C263.063 230.392 269.013 236.007 269.013 242.926C269.013 249.844 263.063 255.46 255.734 255.46Z" fill="#B96613"/>
    <path d="M255.734 301.238H140.649C133.32 301.238 127.371 295.622 127.371 288.704C127.371 281.785 133.32 276.17 140.649 276.17H255.734C263.063 276.17 269.013 281.785 269.013 288.704C269.013 295.622 263.063 301.238 255.734 301.238Z" fill="#B96613"/>
    <path d="M78.8237 358C68.4243 358 60 350.048 60 340.232V59.7677C60 49.9517 68.4243 42 78.8237 42H223.488C233.887 42 242.312 49.9517 242.312 59.7677V110.173C242.312 123.426 253.734 134.208 267.775 134.208H321.176C331.576 134.208 340 142.159 340 151.976C340 161.792 331.576 169.743 321.176 169.743H267.775C232.959 169.743 204.641 143.013 204.641 110.151V77.5129H97.6237V340.21C97.6237 350.026 89.1994 357.978 78.8 357.978L78.8237 358Z" fill="#995314"/>
    </svg>`,
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Dashboard',
        komicon: 'k-home-hashtag',
        route: 'komform-dashboard',
        resource: 'Komform',
        action: 'manage',
      },
    ],
  },
]
