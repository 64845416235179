export default [
  {
    title: 'Campaign',
    komicon: 'k-share-1',
    resource: 'Campaign',
    route: 'notification',
    action: 'manage',
    children: [
      {
        title: 'Notification',
        komicon: 'k-notification',
        tagVariant: 'light-warning',
        route: 'notification',
        resource: 'Campaign',
        action: 'read',
      },
      {
        title: 'Blast Email',
        komicon: 'k-sms',
        tagVariant: 'light-warning',
        route: 'blast-email',
        resource: 'Campaign',
        action: 'read',
      },
    ],
  },
]
