export default [
  {
    title: 'Kelola Admin',
    komicon: 'k-security-user',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Role Akses',
        komicon: 'k-shield-security',
        route: 'role-access',
        resource: 'Kelola User',
        action: 'manage',
      },
      {
        title: 'User',
        komicon: 'k-key-square',
        route: 'user',
        resource: 'Kelola User',
        action: 'manage',
      },
    ],
  },
]
