export default [
  {
    title: 'Affiliate',
    customicon: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M12.277 6.93335L12.2667 18.1321L13.3231 18.1333L13.3333 6.93457L12.277 6.93335Z" fill="#FBBC05"/>
    <path d="M11.6866 18.1333L8.53333 23.9524L9.64677 24.5333L12.8 18.7142L11.6866 18.1333Z" fill="#FBBC05"/>
    <path d="M19.6211 12.8L12.8 16.5822L13.4456 17.6L20.2667 13.8178L19.6211 12.8Z" fill="#FBBC05"/>
    <path d="M14.1358 17.0667L13.3333 18.0224L22.1308 25.6L22.9333 24.6442L14.1358 17.0667Z" fill="#FBBC05"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3334 13.8667C15.1007 13.8667 16.5334 15.4188 16.5334 17.3334C16.5334 19.248 15.1007 20.8 13.3334 20.8C11.566 20.8 10.1334 19.248 10.1334 17.3334C10.1334 15.4188 11.566 13.8667 13.3334 13.8667Z" fill="#FBBC05"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.33331 22.9333C10.0696 22.9333 10.6667 23.5304 10.6667 24.2667C10.6667 25.0029 10.0696 25.6 9.33331 25.6C8.59705 25.6 8 25.0029 8 24.2667C8 23.5304 8.59705 22.9333 9.33331 22.9333Z" fill="#FBBC05"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0667 5.33325C13.8029 5.33325 14.4 5.93034 14.4 6.66659C14.4 7.40283 13.8029 7.99992 13.0667 7.99992C12.3304 7.99992 11.7333 7.40283 11.7333 6.66659C11.7333 5.93034 12.3304 5.33325 13.0667 5.33325Z" fill="#FBBC05"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6 21.8667C22.9251 21.8667 24 22.9416 24 24.2667C24 25.5918 22.9251 26.6667 21.6 26.6667C20.2749 26.6667 19.2 25.5918 19.2 24.2667C19.2 22.9416 20.2749 21.8667 21.6 21.8667Z" fill="#FBBC05"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8 10.1333C22.2723 10.1333 23.4667 11.2082 23.4667 12.5333C23.4667 13.8584 22.2723 14.9333 20.8 14.9333C19.3276 14.9333 18.1334 13.8584 18.1334 12.5333C18.1334 11.2082 19.3276 10.1333 20.8 10.1333Z" fill="#FBBC05"/>
    </svg>`,
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Dashboard',
        komicon: 'k-home-hashtag',
        tagVariant: 'light-warning',
        route: 'dashboard-affiliate',
        resource: 'Affiliate',
        action: 'manage',
      },
      {
        title: 'Leaderboard',
        komicon: 'k-chart-2',
        route: 'affiliate-leaderboard',
        resource: 'Affiliate',
        action: 'manage',
      },
    ],
  },
]
