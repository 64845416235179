export default [
  {
    title: 'PUMKM',
    customicon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_4" data-name="Layer 4" viewBox="0 0 1217.36 1080">
    <defs>
      <style>
        .cls-1 {
          fill: #4285f4;
        }
  
        .cls-2 {
          fill: #fbbc05;
        }
  
        .cls-3 {
          fill: #34a853;
        }
      </style>
    </defs>
    <circle class="cls-1" cx="250.95" cy="820.23" r="128.21"/>
    <circle class="cls-2" cx="395.18" cy="430.06" r="128.21"/>
    <path class="cls-3" d="m887.1,910.63l-184.28-390.5c-30.04-63.67.81-139.41,66.79-163.96h0c64.86-24.13,136.66,11.35,156.88,77.54l126.17,412.95c13.76,45.03-9.94,92.98-54.07,109.39h0c-43.32,16.12-91.77-3.62-111.5-45.42Z"/>
    <circle class="cls-3" cx="715.05" cy="209.51" r="128.21"/>
    <path class="cls-2" d="m682.27,535.27h0c-52.66-40.98-130.17-34.74-171.44,17.69-46,58.44-28.66,142.96,34.88,179.08l287.12,163.22c9.09,5.17,19.46-4.37,15.06-13.86l-152.8-330.01c-2.92-6.32-7.32-11.84-12.81-16.12Z"/>
    <path class="cls-1" d="m539.35,758.53h0c-69.08-4.13-127.38,50.78-127.38,119.99h0c0,70.4,60.23,125.73,130.38,119.78l336.95-28.61c11.06-.94,14.25-15.62,4.57-21.06l-331.85-186.38c-3.89-2.18-8.22-3.45-12.66-3.72Z"/>
  </svg>`,
    tagVariant: 'light-warning',
    children: [
      {
        title: 'List Pendamping',
        komicon: 'k-people',
        tagVariant: 'light-warning',
        route: 'pendamping-umkm',
        resource: 'PUMKM',
        action: 'manage',
      },
    ],
  },
]
