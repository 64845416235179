export default [
  {
    title: 'Keuangan',
    komicon: 'k-receive-square',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'WD Partner',
        tagVariant: 'light-warning',
        komicon: 'k-user-tick',
        route: 'cod-pencairan',
        resource: 'Pencairan',
        action: 'manage',
      },
      {
        title: 'WD Mitra',
        tagVariant: 'light-warning',
        komicon: 'k-profile-2user',
        route: 'withdrawal-mitra',
        resource: 'Pencairan',
        action: 'manage',
      },
      {
        title: 'WD Affiliate',
        tagVariant: 'light-warning',
        komicon: 'k-people',
        route: 'withdrawal-affiliate',
        resource: 'Pencairan',
        action: 'manage',
      },
      {
        title: 'WD Collaborator',
        tagVariant: 'light-warning',
        komicon: 'k-hashtag-1',
        route: 'withdrawal-collaborator',
        resource: 'Pencairan',
        action: 'manage',
      },
      {
        title: 'Mutasi Bank',
        tagVariant: 'light-warning',
        komicon: 'k-card',
        route: 'mutation-bank',
        resource: 'MutationBank',
        action: 'manage',
      },
    ],
  },
]
